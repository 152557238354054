export const vars = {
	SUCCESS_API_CODE: '000',
	SITE_NAME: 'SharPay',
	DEFAULT_LANGUAGE: 'en',
	NOTIFY: {
		ERROR: 'error',
		SUCCESS: 'success',
	},
	ACCOUNT_LOCK_ID: 1,
	ADD_ACCOUNT: {
		WALLETTO_ACCOUNT_TYPE_ID: 3,
    PLASTIC_CARD_KIND: 1,
    VIRTUAL_CARD_KIND: 3,
	},
  GBP: 826,
	MAIN: {
		STATUS_COLOR_CLASS: {
			'GREEN': 'col-green-underline',
			'RED': 'col-red-underline',
			'ORANGE': 'col-orange-underline',
		},
		ACCOUNTS_NAME: {
			WALLETTO: 'SharPay Card',
			SHARPAY_IBAN: 'SharPay IBAN',
		},
		ACCOUNTS: {
			SHARPAY_ID: 0,
			WALLETTO_ID: 11,
			SHARPAY_IBAN_ID: 12,
			SEPA_ID: 13,
			BANXE_ID: 21,
		},
		SET_PIN_ID: {
			NOT_REQUESTED: 0,
			SUCCESS: 1,
			FAILED: 2,
		},
		WALLETTO_NOT_ACTIVATED_ID: 3,
		DETAIL_WIDGET_SETTING_ID: {
			CHANGE_3D_SECURE: 1,
			CHANGE_PIN: 2,
			LOCK_CARD: 3,
			UNLOCK_CARD: 4,
		}
	},
	PAYMENT: {
		PAYMENT_TYPE_INIT: 0,
		PAYMENT_TYPE_PAYOUT: 1,
		PAYMENT_TYPE_INCOME: 2,
		PAYMENT_SERVICE: 3,
		PAYOUT_PATH: '/operations/payout',
		INCOME_PATH: '/operations/income',
	},
  PAYMENT_STATUS: {
    IN_PROGRESS: 2,
    CONFIRMED: 4,
    ERROR: 5,
    REJECTED: 6,
  },
	OPERATION: {
		PATH: '/operations',
		INTERNAL_TRANSFERS_CODE: 0,
		IS_CHECK_OTP_CODE: 1,
		PAYMENT_PAYOUT: 'Payment/Payout',
		PAYMENT_INCOME: 'Payment/Income',
		PAYMENT_TO_CARD: 'Payment/CreateCardPayment',
		NEED_CREDIT_FORM_ID: 1,
		CRYPTO_KIND: 4,
		SWIFT_PAYMENT_TYPE: 16,
		FEE_PAYER_TYPE_OUR: 3,
		FORMATTED_CARD_NUMBER_LENGTH: 19, // 4*4 digit and 3 space
		EXPIRY_DATE_LENGTH: 5, // 2 for month 2 for year digit and 1 slash delimiter
		CRYPTO_CURRENCY_ID: 2,
		FIAT_CURRENCY_ID: 1,
		SWIFT_CODE_LENGTH: 11,
		EUR_CURRENCY: {
			Code: 'EUR',
			CurrencyTypeID: 1,
      ViewPrecision: 2,
			ID: 978,
			Symbol: '€',
		},
	},
	FIELDS: {
		DESCRIPTION: 'description',
	},
	AUTH_TYPE: {
		SIMPLE: 1,
		TWO_FACTOR: 2,
	},
	REG_EXP: {
		PASSWORD_PATTERN: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})/,
		NUMBERS_ONLY: /^\d+$/,
		NUMBERS_WITH_SPACE_ONLY: /^(?=.*\d)[\d ]+$/,
		FOUR_NUMBERS_ONLY: /^\d{4}$/,
		THREE_NUMBERS_ONLY: /^\d{3}$/,
		LATIN_ONLY: /^[a-zA-Z\s]+$/,
		EXPIRY_DATE: /^\d{2}\/\d{2}$/,
		SPACES: / /g,
		PHONE_NUMBER: /^\+?\d+$/,
    DASHED_PHONE_NUMBER: /^[+\d\s-]+$/,
		AMOUNT: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
	},
	MESSAGES: {
		NUMBERS_ONLY_TEXT: 'Value must be a number',
		ENTER_CONFIRMATION_CODE: 'Введите код подтверждения для активации аутентификации.',
	},
	METHOD_TYPE: {
		UNSPECIFIED: 0,
		EMAIL: 1,
		SMS: 2,
	},
	SETTINGS_SCREENS: {
		PROFILE: 'Profile',
		LegalInformation: 'LegalInformation',
		NOTIFICATIONS: 'Notifications',
		SECURITY: 'Security',
		SCIAPI: 'SciApi',
		CONNECTIONS: 'Connections',
		VERIFICATION: 'Verification',
	},
	PROFILE: {
		VERIFICATED_STATUS: 2,
		PERSONAL_ACCOUNT: 1,
		BUSINESS_ACCOUNT: 2,
		IS_PHONE_VERIFY: 1,
		IS_EMAIL_VERIFY: 1,
	},
	SCIAPI: {
		PAYMENTS_SYSTEM_ID: {
			0: 'sharpay',
			1: 'advcash',
			2: 'webmoney',
			3: 'connectum',
			4: 'perfect',
			5: 'billline',
			6: 'neteller',
			7: 'scrill',
			8: 'paypal',
			9: 'payeer',
			10: 'cardpay',
			11: 'sharpay-card',
		}
	},
	POPUP_LOGO_FILE_NAME: [
		'SP_80_80_3.svg',
		'push.gif',
		'call.gif',
		'sms.gif',
		'email.gif',
	],
};

export const apiDateFormats = {
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
}

export const dateFormats = {
  apiDateFormat: 'YYYY-MM-DD',
  apiDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  viewDateFormat: 'YYYY.MM.DD',
  viewDateTimeFormat: 'YYYY.MM.DD, HH:mm',
}

export const progressColor = {
	EMAIL: '#4cd964',
	PHONE: '#9013fe',
	PERSON: '#ffc951',
	PERSON_LIVING: '#ff0085',
};

export const languages = [
	{
		name: 'en',
		imgSrc: '/images/flags/gb.svg',
	},
	{
		name: 'ru',
		imgSrc: '/images/flags/ru.svg',
	},
	{
		name: 'uk',
		imgSrc: '/images/flags/ua.svg',
	},
];

const {MAIN : {ACCOUNTS: {SHARPAY_IBAN_ID, SEPA_ID, BANXE_ID}}} = vars;

export const specialPaymentMethodValueIds = [SHARPAY_IBAN_ID, SEPA_ID, BANXE_ID]; // IBAN & SEPA & BANXE

export const conditionLinks = {
	docHost: 'https://sharpay.net/docs/',
	termsAndConditions: 'https://sharpay.net/docs/Terms_and_Conditions_eng.pdf',
};

export const verificationStatus = {
	VERIFIED : 2,
	DECLINED : 3,
	NEED_EXTRA_DOCS : 4,
	DECLINED_AFTER_VERIFIED : 5,
	NEED_APPROVAL_FROM_BACKOFFICE : 6,
	IN_PROGRESS : 7,
	NEED_EXTRA_DOCS_AFTER_VERIFIED : 8,
	IN_PROCESS_AFTER_VERIFIED : 9,
}

export const descriptions = [
  '',
  '',
  'DESC_1',
  'DESC_2',
  'DESC_3',
  'DESC_4',
];
