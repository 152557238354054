import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import {Button, CheckBox, SelectBox, TextBox, Validator} from 'devextreme-react';
import {CustomRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import CurrencySelectBox from 'components/operations/blocks/currency-selectbox';
import {withTranslation} from 'react-i18next';
import {
  calculateFee,
  checkInternalAccount,
  getAllowedPaymentParams,
  getCurrencyList,
  makeTransaction,
  paymentConfirm,
  resendPaymentOtp
} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {constructMinMaxString} from 'utils/functions';
import validationEngine from 'devextreme/ui/validation_engine';
import {vars} from 'utils/variables';
import RecipientItemTemplate from './recipient-item-template';
import RecipientFieldTemplate from './recipient-field-template';
import Loader from 'components/loader/loader';
import RoundButton from 'components/buttons/round-button/round-button';
import {getCustomDataSource} from 'services/customDataSource';
import RecipientMaskItemTemplate from './recipient-mask-item-template';
import RecipientMaskFieldTemplate from './recipient-mask-field-template';

const {
  GBP,
  NOTIFY,
  PAYMENT,
  OPERATION,
  REG_EXP,
  MAIN: {ACCOUNTS},
} = vars;

const initialState = {
  foreignAccountCode: null,
  amount: null,
  toCurrencyAmount: null,
  description: null,
  fee: {
    ToCurrencyAmount: null,
    CurrencyRate: null,
    TotalAmount: -1,
    FeeAmount: -1,
  },
  allowedPaymentParams: null,
  isChecked: false,
  foreignAccountFieldValue: null,
  toOthersActive: true,
};

class PayoutFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({
      currencyList: null,
      toCurrency: null,
    }, initialState);

    this.recipientNamePattern = /^[a-zA-Z ]*$/;
    this.companyNamePattern = /^[^\u0400-\u04FF]*$/; // Not in Cyrillic Unicode range
    this.banxeFieldRef = React.createRef();
  }

  async componentDidMount() {
    const currency = await getCurrencyList();
    if (currency.isSuccess) {
      this.setState({
        currencyList: currency.list
      });
    } else {
      notifyApp(currency.error, NOTIFY.ERROR);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {paymentMethodValueId} = this.props;

    if (paymentMethodValueId !== prevProps.paymentMethodValueId) {
      this.setState(Object.assign({
        ...this.state,
        toCurrency: OPERATION.EUR_CURRENCY,
      }, initialState));
    }
  }

  onClickCancel = () => {
    //this.rejectPreviousPayment().catch((error) => {});
    this.props.clickCancel();
  };

  rejectPreviousPayment = async () => {

  };

  currencyHandler = ({value}) => {
    const {currencyList} = this.state;
    const selectedCurrency = currencyList.find(item => item.ID === value);
    this.setState({
      toCurrency: selectedCurrency
    }, this.calculateAmountFee)
  };

  amountChanged = ({value}) => {
    if (!value) return;
    const preparedValue = value.trim();
    this.setState({
      amount: preparedValue,
      toCurrencyAmount: null,
    }, preparedValue > 0 ? this.calculateAmountFee : null);
  };

  toCurrencyAmountChanged = ({value}) => {
    if (!value) return;

    const preparedValue = value.trim();

    this.setState({
      amount: null,
      toCurrencyAmount: preparedValue,
    }, preparedValue > 0 ? this.calculateAmountFee : null);
  };

  getBanxeRecipient = async (foreignAccountCode, needFeeCalculate) => {
    const {account, t} = this.props;

    const request = await checkInternalAccount(foreignAccountCode, account.CurrencyID);
    const textBox = this.banxeFieldRef.current.instance.element();

    if (request.isSuccess) {
      const {MerchantName, CurrencySymbol, CurrencyID, CurrencyCode} = request.account;

      if (MerchantName) {
        this.setState({
          recipientName: MerchantName,
          toCurrency: {
            Symbol: CurrencySymbol,
            ID: CurrencyID,
            Code: CurrencyCode,
          },
          isBanxeFieldValid: true,
        }, () => {
          textBox.classList.remove('dx-invalid');
          textBox.classList.add('dx-valid');

          if (needFeeCalculate) {
            this.calculateAmountFee();
          }
        });
      }
    } else {
      this.setState({
        isBanxeFieldValid: false,
      }, () => {
        textBox.classList.remove('dx-valid');
        textBox.classList.add('dx-invalid');
      });

      notifyApp(t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.ACCOUNT_NOT_FOUND'));
    }
  }


  foreignAccountCodeChangedCallback = async () => {
    const {amount, toCurrencyAmount, foreignAccountCode, toCurrency} = this.state;
    const {paymentMethodValueId} = this.props;

    const needFeeCalculate = amount > 0 || toCurrencyAmount > 0;

    try {
      if (paymentMethodValueId === ACCOUNTS.BANXE_ID) {
        await this.getBanxeRecipient(foreignAccountCode, needFeeCalculate);
      } else {
        const request = await checkInternalAccount(foreignAccountCode);

        if (request.isSuccess) {
          const internalAccount = request.account;
          const internalCurrency = {
            Symbol: internalAccount.CurrencySymbol,
            ID: internalAccount.CurrencyID,
            Code: internalAccount.CurrencyCode,
          };

          this.setState({
            recipientName: internalAccount.MerchantName || '',
            toCurrency: internalCurrency,
          }, needFeeCalculate ? this.calculateAmountFee : null)
        }
      }
    } finally {
      await this.requestMinMax(null, foreignAccountCode);
    }
  }

  foreignAccountCodeChanged = async (props) => {
    const {value, component} = props;
    if (!value) {
      this.setState({foreignAccountCode: null});
      return;
    }

    const {recipientAccountsList, paymentMethodValueId} = this.props;
    const account = recipientAccountsList.find(item => item.ID === value);

    let foreignAccountCode;
    let toCurrency = null;

    if (account) {
      const recipientMethodKey = paymentMethodValueId === ACCOUNTS.WALLETTO_ID ? 'CardNumber' : 'AccountNumber';
      const {CurrencySymbol, CurrencyID, CurrencyCode} = account;

      foreignAccountCode = account[recipientMethodKey];
      toCurrency = {
        Symbol: CurrencySymbol,
        ID: CurrencyID,
        Code: CurrencyCode,
      };
    } else {
      foreignAccountCode = component.option('text');
    }

    const newState = {
      foreignAccountCode: foreignAccountCode,
      foreignAccountFieldValue: value,
      toCurrency: toCurrency,
    }

    this.setState(newState, this.foreignAccountCodeChangedCallback);
  };

  /*foreignClientAddressChanged = ({value}) => {
    this.setState({
      foreignClientAddress: value,
    });
  }*/

  foreignBankSwiftChanged = ({value}) => {
    this.setState({
      foreignBankSwift: value,
    });
  }

  descriptionValueChanged = ({value}) => {
    const {amount, toCurrencyAmount} = this.state;
    const needCallback = amount > 0 || toCurrencyAmount > 0;
    this.setState({
      description: value.trim()
    }, needCallback ? this.calculateAmountFee : null);
  };

  calculateAmountFee = async () => {
    const {account, paymentMethodValueId} = this.props;
    const {amount, foreignAccountCode, toCurrency, toCurrencyAmount} = this.state;

    const requestData = {
      amount: amount, // field how much
      toCurrencyAmount: toCurrencyAmount, // field how much total
      accountId: account.ID, // from account object
      foreignAccountCode: foreignAccountCode, // field to whom
      paymentSystemTypeID: paymentMethodValueId, // field radio-selectbox
      paymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT, // payout or income
      currency: toCurrency && toCurrency.ID, // field to currency
    };

    if (account.PaymentSystemTypeID === ACCOUNTS.SHARPAY_IBAN_ID && paymentMethodValueId === ACCOUNTS.BANXE_ID) {
      requestData.currency = OPERATION.EUR_CURRENCY.ID;
    }

    if (!foreignAccountCode) {
      validationEngine.validateGroup('gr1');
      return;
    }

    const request = await calculateFee(requestData);

    if (request.isSuccess) {
      this.setState({
        fee: request.response
      });
    } else {
      notifyApp(`Fee calculation error: ${request.error}`, NOTIFY.ERROR);
    }
  };

  requestMinMax = async (accountId, accountCode) => {
    const {account, selectedMethod, recipientAccountsList} = this.props;
    const {currencyList, toCurrency} = this.state;

    if (accountId || accountCode) {
      const selectedFromListAccount = recipientAccountsList.find((item) => {
        return item.ID === accountId;
      });

      const params = {
        AccountID: account.ID,
        PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT,
        ForeignPaymentSystemTypeID: selectedMethod.ForeignPaymentSystemTypeID,
      };

      if (selectedFromListAccount) {
        params.ForeignAccountID = selectedFromListAccount.ID;
      } else {
        params.ForeignAccountCode = accountCode ? accountCode : selectedFromListAccount.Code;
      }

      const request = await getAllowedPaymentParams(params);

      if (request.isSuccess && request.response.length > 0) {
        const allowedPaymentParams = request.response;

        const allowedCurrencyIds = allowedPaymentParams.map((item) => {
          return item.ForeignCurrencyID;
        });

        const limitedCurrencyList = currencyList.filter((currency) => {
          return allowedCurrencyIds.includes(currency.ID);
        });

        const newState = {
          allowedPaymentParams: allowedPaymentParams,
          currencyList: limitedCurrencyList,
          toCurrency: (toCurrency && [0, 11, 12, 21].includes(selectedMethod.ForeignPaymentSystemTypeID)) ?
            toCurrency : limitedCurrencyList[0],

          toCurrencyOld: selectedMethod.ForeignPaymentSystemTypeID === OPERATION.INTERNAL_TRANSFERS_CODE ? // 0, 11, 12, 21
            toCurrency :
            limitedCurrencyList[0]
        };

        if (limitedCurrencyList.length === 0) {
          if (selectedMethod.ForeignPaymentSystemTypeID === ACCOUNTS.WALLETTO_ID) {
            newState.toCurrency = {
              Code: account.CurrencyCode,
              CurrencyTypeID: account.CurrencyTypeID,
              ID: account.CurrencyID,
              Symbol: account.CurrencySymbol,
            }; // origin account currency
          } else if (selectedMethod.ForeignPaymentSystemTypeID === ACCOUNTS.SHARPAY_IBAN_ID) {
            newState.toCurrency = OPERATION.EUR_CURRENCY; // EUR
          }
        }

        this.setState(newState);
      } else {
        if (request.error) {
          notifyApp(request.error);
        }
      }
    }
  }

  isInternalAccount = () => {
    const {paymentMethodValueId} = this.props;
    return !([ACCOUNTS.SHARPAY_IBAN_ID, OPERATION.INTERNAL_TRANSFERS_CODE].includes(paymentMethodValueId));
  };

  customRuleCallback = async ({value}) => {
    const {amount, toCurrencyAmount} = this.state;
    const {paymentMethodValueId, t} = this.props;
    const needCallback = (amount > 0 || toCurrencyAmount > 0);

    try {
      if (this.isInternalAccount()) {
        if (needCallback) {
          this.calculateAmountFee();
        }
        return true;
      }

      const request = await checkInternalAccount(value.trim());

      if (request.isSuccess) {
        const internalAccount = request.account;
        if (internalAccount) {
          this.setState({
            recipientName: internalAccount.MerchantName || '',
            toCurrency: {
              Symbol: internalAccount.CurrencySymbol,
              ID: internalAccount.CurrencyID,
              Code: internalAccount.CurrencyCode,
            }
          }, needCallback ? this.calculateAmountFee : null);
          return true;
        } else {
          if (paymentMethodValueId === ACCOUNTS.SHARPAY_IBAN_ID) {
            this.setState({
              recipientName: '',
            });
          } else {
            notifyApp(t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.ACCOUNT_NOT_FOUND'));
          }

          return paymentMethodValueId === ACCOUNTS.SHARPAY_IBAN_ID;
        }
      } else {
        if (request.error) {
          notifyApp(request.error);
        }
        return false;
      }
    } finally {
      await this.requestMinMax(value);
    }
  };

  onContinue = async ({component}) => {
    const {
      amount,
      toCurrency,
      description,
      foreignAccountCode,
      fee,
      recipientName,
      isChecked,
      //foreignClientAddress,
      foreignBankSwift,
    } = this.state;

    const amountValue = amount ? amount : fee.Amount;
    const {
      paymentMethodValueId, account, changePaymentStatus, t, showOtpPopup, hideOtpPopup,
    } = this.props;
    const result = validationEngine.validateGroup('gr1');

    if (result.isValid) {
      component.option('disabled', true);

      let transactionParams = {
        PaymentSystemTypeID: paymentMethodValueId,
        AccountID: account.ID,
        Amount: amountValue,
        ForeignCurrencyID: toCurrency && toCurrency.ID,
        Description: description,
        ForeignAccountCode: foreignAccountCode,
        ForeignClientName: recipientName && recipientName.toUpperCase(),
        operation: OPERATION.PAYMENT_PAYOUT
      };

      if (account.PaymentSystemTypeID === ACCOUNTS.SHARPAY_IBAN_ID && paymentMethodValueId === ACCOUNTS.BANXE_ID) {
        transactionParams.ForeignCurrencyID = OPERATION.EUR_CURRENCY.ID;
      }

      let customParams = null;

      if (account.PaymentSystemTypeID === ACCOUNTS.BANXE_ID && paymentMethodValueId !== ACCOUNTS.BANXE_ID) {
        delete transactionParams.ForeignClientName;

        const [firstName, ...other] = recipientName.split(' ');

        customParams = {
          ForeignFirstName: firstName.toUpperCase(),
          ForeignLastName: other.join(' ').toUpperCase() || '',
        };

        if (isChecked) {
          customParams = {
            ForeignCompanyName: recipientName.toUpperCase(),
          }
        }

        transactionParams = Object.assign(transactionParams, {
          //ForeignClientAddress: foreignClientAddress,
          ForeignBankSwift: foreignBankSwift,
        });
      }

      if (!transactionParams.ForeignClientName) {
        delete transactionParams.ForeignClientName;
      }

      try {
        const request = await makeTransaction(transactionParams, customParams);

        if (request.isSuccess) {
          if (request.response.IsCheckOtp === OPERATION.IS_CHECK_OTP_CODE) {
            // with otp
            showOtpPopup({
              onSubmit: async (otpCode) => {
                const confirmRequest = await paymentConfirm({
                  PaymentID: request.response.ID,
                  Code: otpCode,
                });

                if (confirmRequest.isSuccess) {
                  // done
                  hideOtpPopup();
                  changePaymentStatus({
                    text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
                  });
                } else {
                  notifyApp(confirmRequest.error);
                }
              },
              onResend: async () => {
                const resendRequest = await resendPaymentOtp({
                  PaymentID: request.response.ID,
                });

                if (resendRequest.isSuccess) {
                  notifyApp(t('COMMON.SENT'), NOTIFY.SUCCESS);
                } else {
                  notifyApp(resendRequest.error);
                }
              },
              sendChannelId: request.response.SendChannelID,
              infoText: request.response.InfoText,
            });
          } else {
            changePaymentStatus({
              text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
            });
          }
        } else {
          notifyApp(request.error, NOTIFY.ERROR);
        }
      } catch (error) {
        notifyApp(error);
      } finally {
        component.option('disabled', false);
      }
    } else {
      // focus first broken field
      result.brokenRules[0].validator.focus();
    }
  };

  checkBalance = ({value}) => {
    const {account} = this.props;
    return account.Balance >= value;
  };

  handleCheckBox = ({value}) => {
    this.setState({isChecked: value});
  }

  onCustomForeignAccountCreating = (args) => {
    if (!args.text) {
      args.customItem = null;
      return;
    }

    const {paymentMethodValueId} = this.props;
    const recipientMethodKey = paymentMethodValueId === ACCOUNTS.WALLETTO_ID ? 'CardNumber' : 'AccountNumber';

    const {component, text} = args;
    const currentItems = component.option('items');

    let newId;

    if (currentItems) {
      const ids = currentItems.map(item => item.ID);
      newId = Math.max(...ids) + 1;
    } else {
      newId = 1;
    }

    const newItem = {
      ID: newId,
      [recipientMethodKey]: text.trim(),
    };

    const itemInDataSource = currentItems.find((item) => item.Code === newItem.text);

    if (itemInDataSource) {
      args.customItem = itemInDataSource;
    } else {
      currentItems.push(newItem);
      //component.option('items', currentItems);
      args.customItem = newItem;
    }
  }

  maskCardsDatasource = () => {
    const {account, selectedMethod} = this.props;

    const dataSource = getCustomDataSource({
      dictObj: {
        objectName: 'ForeignAccount',
        operation: 'GetLastMaskCards',
        responseKey: 'GetLastMaskCards',
      },
      requestParams: {
        AccountID: account.ID,
        PaymentSystemTypeID: selectedMethod.ForeignPaymentSystemTypeID,
      },
      keyField: 'ForeignAccountCode',
      displayFld: 'ForeignAccountCode',
    });

    return dataSource;
  };

  render() {
    const {
      paymentMethodValueName,
      paymentMethodValueId,
      account,
      t,
      isShowLoader,
      selectedMethod,
      recipientAccountsList,
    } = this.props;

    const {
      currencyList,
      toCurrency,
      description,
      amount,
      toCurrencyAmount,
      fee,
      recipientName,
      allowedPaymentParams,
      foreignAccountCode,
      isChecked,
      //foreignClientAddress,
      foreignBankSwift,
      foreignAccountFieldValue,
      toOthersActive,
    } = this.state;

    if (toCurrency) {
      toCurrency.ID = parseInt(toCurrency.ID);
    }

    const amountValue = amount ? amount : fee ? fee.Amount : null;
    const recipientMethodKey = paymentMethodValueId === ACCOUNTS.WALLETTO_ID ? 'CardNumber' : 'AccountNumber';
    const foreignBankKey = account.CurrencyID === GBP ? 'FOREIGN_BANK_SORT_CODE' : 'FOREIGN_BANK_SWIFT';
    const foreignBankSwiftLabel = t('FIELDS.' + foreignBankKey);
    const needAdditionalFields = account.PaymentSystemTypeID === ACCOUNTS.BANXE_ID;

    return (
      <div className={'payout-fields'}>
        <Loader
          isShowLoader={isShowLoader}
        />
        <div className={'operations-row'}>
          <div className={'operations-row-inner'}>
            <div
              className={'operations-subtitle'}>{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TRANSFER_TO')} ${paymentMethodValueName}`}</div>
            <div className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DETAILS')}</div>
            <div className={'operations-fields-wrapper'}>
              <div className={'operations-fieldset dense'}>
                <div className={'field-mode-buttons operations-field-buttons'}>
                  <RoundButton
                    height={28}
                    text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TO_OWN')}
                    customClass={classNames('grey-blue-btn', {active: !toOthersActive})}
                    onClick={() => {
                      this.setState({toOthersActive: false});
                    }}
                  />
                  <RoundButton
                    height={28}
                    text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TO_OTHERS')}
                    customClass={classNames('grey-blue-btn', {active: toOthersActive})}
                    onClick={() => {
                      this.setState({toOthersActive: true});
                    }}
                  />
                </div>
                <div className={'operations-field'}>
                  <div
                    className={'dx-field-label'}
                  >
                    {`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.RECIPIENT')}*`}
                  </div>
                  <div className={'dx-field-value'}>
                    {toOthersActive ? (
                      <TextBox
                        ref={this.banxeFieldRef}
                        value={foreignAccountCode}
                        className={'operation-input'}
                        stylingMode={'outlined'}
                        hoverStateEnabled={false}
                        name={'foreignAccountCode'}
                        onValueChanged={this.foreignAccountCodeChanged}
                      >
                        <Validator validationGroup={'gr1'}>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        </Validator>
                      </TextBox>
                    ) : (
                      <>
                        {(
                          selectedMethod.ForeignPaymentSystemTypeKindID === 1 &&
                          selectedMethod.ForeignPaymentSystemTypeIsShowCardForm === 1
                        ) ? (
                          <SelectBox
                            dataSource={this.maskCardsDatasource()}
                            displayExpr={'ForeignAccountCode'}
                            valueExpr={'ForeignAccountCode'}
                            value={foreignAccountFieldValue}
                            name={'foreignAccountCode'}
                            className={'operation-input'}
                            stylingMode={'outlined'}
                            itemComponent={RecipientMaskItemTemplate}
                            fieldComponent={RecipientMaskFieldTemplate}
                            onValueChanged={this.foreignAccountCodeChanged}
                          >
                            <Validator validationGroup={'gr1'}>
                              <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                            </Validator>
                          </SelectBox>
                        ) : (
                          <SelectBox
                            dataSource={recipientAccountsList}
                            displayExpr={recipientMethodKey}
                            valueExpr={'ID'}
                            value={foreignAccountFieldValue}
                            name={'foreignAccountCode'}
                            className={'operation-input'}
                            stylingMode={'outlined'}
                            onValueChanged={this.foreignAccountCodeChanged}
                            itemComponent={RecipientItemTemplate}
                            fieldComponent={RecipientFieldTemplate}
                            acceptCustomValue={false}
                          >
                            <Validator validationGroup={'gr1'}>
                              <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                            </Validator>
                          </SelectBox>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className={classNames('operations-fieldset', {
                'smooth-top': needAdditionalFields,
              })}>
                <div className={'operations-field'}>
                  <div
                    className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.RECIPIENT_NAME')}*`}</div>
                  <div className="dx-field-value">
                    <TextBox
                      value={recipientName}
                      className={'operation-input recipient-name'}
                      stylingMode={'outlined'}
                      hoverStateEnabled={false}
                      name={'recipient-name'}
                      onValueChanged={({value}) => {
                        this.setState({
                          recipientName: value,
                        })
                      }}
                    >
                      <Validator validationGroup={'gr1'}>
                        <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        <PatternRule
                          pattern={isChecked ? this.companyNamePattern : this.recipientNamePattern}
                          message={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.LATIN_ONLY')}
                        />
                      </Validator>
                    </TextBox>
                  </div>
                </div>
              </div>

              {needAdditionalFields ? (
                <>
                  <div className={'operations-fieldset smooth-center'}>
                    <div className={'operations-field'}>
                      <div className={'dx-field-label'}></div>
                      <div className={'dx-field checkbox-field'}>
                        <CheckBox
                          id={'company-payment'}
                          validationMessageMode={'always'}
                          value={isChecked}
                          text={''}
                          onValueChanged={this.handleCheckBox}
                        />
                        <div className={'checkbox-text'}>
                          <span>{t('FIELDS.COMPANY_PAYMENT')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'operations-fieldset'}>
                    <div className={'operations-field'}>
                      <div
                        className={'dx-field-label'}
                      >
                        {`${foreignBankSwiftLabel}*`}
                      </div>
                      <div className={'dx-field-value'}>
                        <TextBox
                          value={foreignBankSwift}
                          className={'operation-input'}
                          stylingMode={'outlined'}
                          hoverStateEnabled={false}
                          name={'foreignBankSwift'}
                          onValueChanged={this.foreignBankSwiftChanged}
                        >
                          <Validator validationGroup={'gr1'}>
                            <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className={'operations-row'}>
          <div className={'operations-row-inner'}>
            <div
              className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TRANSFER_AMOUNT')}</div>
            <div className={'operations-fields-wrapper'}>
              <div className={'operations-fieldset'}>
                <div className={'operations-field'}>
                  <div
                    className='dx-field-label'>{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_PAYABLE')}*`}</div>
                  <div className={'dx-field-value field-value-relative'}>
									<span className={'field-value-currency'}>
										{account.CurrencySymbol}
									</span>
                    <TextBox
                      className={'operation-input'}
                      value={amountValue}
                      stylingMode={'outlined'}
                      name={'amount'}
                      onValueChanged={this.amountChanged}
                      hoverStateEnabled={false}
                    >
                      <Validator validationGroup={'gr1'}>
                        <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        <PatternRule message={t('ERROR_MSG.DIGIT_GRATER_NULL_ONLY')}
                                     pattern={REG_EXP.AMOUNT}/>
                        <CustomRule
                          message={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.INSUFFICIENT_BALANCE')}
                          validationCallback={this.checkBalance}
                        />
                      </Validator>
                    </TextBox>
                    <div className={'operations-field-addons'}>
                      {constructMinMaxString(allowedPaymentParams, toCurrency ? toCurrency.ID : OPERATION.EUR_CURRENCY.ID)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'operations-fieldset'}>
                <div className={'operations-field'}>
                  <div
                    className="dx-field-label">{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.FEE')}</div>
                  <div className="dx-field-value field-value-relative">
									<span className={classNames('field-value-currency', 'currency-read-only', {
                    'field-value-currency-display': fee.FeeAmount < 0,
                  })}>
										{account.CurrencySymbol}
									</span>
                    <TextBox
                      className={'operation-input'}
                      value={fee.FeeAmount >= 0 ? fee.FeeAmount.toString() : null}
                      readOnly={true}
                      hoverStateEnabled={false}
                      stylingMode={'outlined'}
                    />
                  </div>
                </div>
              </div>
              <div className={'operations-fieldset'}>
                <div className={'operations-field-full operations-field'}>
                  <div className="operations-field-label dx-field-label">
                    {`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_RECEIVABLE')}*`}
                  </div>
                  <div className="dx-field-value field-value-relative">
                    <CurrencySelectBox
                      paymentMethodValueId={paymentMethodValueId}
                      items={currencyList}
                      fromCurrency={{
                        Symbol: account.CurrencySymbol,
                        ID: account.CurrencyID,
                        Code: account.CurrencyCode,
                      }}
                      toCurrency={toCurrency}
                      toCurrencyAmount={toCurrencyAmount}
                      fee={fee}
                      currencyHandler={this.currencyHandler}
                      onValueChanged={this.toCurrencyAmountChanged}
                      selectedMethod={selectedMethod}
                    />
                  </div>
                </div>
              </div>

              {account.PaymentSystemTypeID === ACCOUNTS.BANXE_ID ? (
                <div className={'operations-fieldset'}>
                  <div className={'operations-field-full operations-field'}>
                    <div className="operations-field-label dx-field-label">
                      {t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.WITHDRAW')}
                    </div>
                    <div className="dx-field-value field-value-relative">
                    <span className={'field-value-currency currency-read-only'}>
										  {account.CurrencySymbol}
									  </span>
                      <TextBox
                        readOnly
                        className={'operation-input'}
                        value={fee.Amount ? fee.Amount + fee.FeeAmount : null}
                        hoverStateEnabled={false}
                        stylingMode={'outlined'}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

            </div>
          </div>
        </div>

        <div className={'operations-row'}>
          <div className={'operations-row-inner'}>
            <div className={'operations-fields-wrapper'}>
              <div className={'operations-fieldset'}>
                <div className={'operations-field details-field'}>
                  <div
                    className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DESCRIPTION')}*`}</div>
                  <div className="dx-field-value dx-field-value-last">
                    <TextBox
                      value={description}
                      placeholder={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DESCRIPTION_FIELD')}
                      stylingMode={'outlined'}
                      hoverStateEnabled={false}
                      name={'description'}
                      onValueChanged={this.descriptionValueChanged}
                    >
                      <Validator validationGroup={'gr1'}>
                        <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                      </Validator>
                    </TextBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'operations-buttons'}>
          <div className={'buttons-wrapper'}>
            <Button
              width={120}
              text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CANCEL')}
              type={'normal'}
              stylingMode={'contained'}
              onClick={this.onClickCancel}
            />
            <Button
              width={120}
              text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CONTINUE')}
              type={'normal'}
              stylingMode={'contained'}
              onClick={this.onContinue}
            />
          </div>
        </div>
      </div>
    );
  }
}

PayoutFields.propTypes = {
  paymentMethodValueName: propTypes.string,
  paymentMethodValueId: propTypes.number,
  account: propTypes.object,
  clickCancel: propTypes.func
};

PayoutFields.defaultProps = {
  paymentMethodValueName: '',
  paymentMethodValueId: 1,
  account: {
    Code: '000-000000',
    ID: 1234,
    CurrencyCode: 840
  },
  clickCancel: () => {
    console.warn('clickCancel not defined');
  }
};

export default withTranslation()(PayoutFields);
