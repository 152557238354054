import axios from 'axios';
import DataSource from 'devextreme/data/data_source';
import {prepareRequestOptions} from './async';

async function load(
  loadOptions, objectName, operation, responseKey, requestParams, keyField, displayFld, additionalCols, sorts, filters,
) {
  const {take, skip} = loadOptions;
  const requestObj = {
    Filters: filters,
    Page: (skip + take) / take || -1,
    RowsPerPage: take,
    Columns: [keyField, ...additionalCols],
  };

  if (requestParams) {
    requestObj.Params = requestParams;
  }

  if (sorts) {
    requestObj.Sorts = [sorts];
  }

  if (displayFld !== keyField) {
    requestObj.Columns.push(displayFld);
  }

  if (!Object.keys(requestObj.Filters).length) {
    delete requestObj.Filters;
  }

  const response = await axios(prepareRequestOptions(
    {operation: `${objectName}/${operation}`, data: requestObj}));

  if (response.data.ResponseCode === '000')
    return response.data.Response[responseKey ? responseKey : objectName];

  return [];
}


export function getCustomDataSource({
                                      dictObj,
                                      requestParams = null,
                                      keyField,
                                      displayFld,
                                      additionalCols = [],
                                      sorts = null,
                                      filters = {}
                                    }) {
  if (!(dictObj && keyField && displayFld)) {
    throw new Error('Dictionary field initialization error');
  }

  const {objectName, operation = 'List', responseKey} = dictObj;

  return new DataSource({
    load: (loadOptions) => load(
      loadOptions, objectName, operation, responseKey, requestParams, keyField, displayFld, additionalCols, sorts, filters,
    ),
  });
}