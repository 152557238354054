import React from 'react';
import {vars} from 'utils/variables';

const {PAYMENT: {PAYMENT_TYPE_INCOME}} = vars;

const ItemTemplate = ({data}) => {
	const {
		CurrencyCode,
		CurrencyName,
		BlockchainName,
		ImageUrl,
		AccountCurrencySymbol,
		DisplayCurrencyRate,
		DisplayBuyCurrencyRate,
		PaymentTypeID,
	} = data;

	const rateValue = PaymentTypeID === PAYMENT_TYPE_INCOME ? DisplayCurrencyRate : DisplayBuyCurrencyRate;

	return (
		<div className={'crypto-item'}>
			<div className={'image-wrapper'}>
				{ImageUrl && (
					<img src={ImageUrl} alt={CurrencyName}/>
				)}
			</div>
			<div className={'crypto-item-wrapper'}>
				<div className={'left'}>
					<div className={'left-top'}>
						<span className={'left-top-bold'}>{CurrencyCode}</span>
						<span className={'left-top-margin'}>{CurrencyName}</span>
					</div>
					<span className={'left-bottom'}>{BlockchainName}</span>
				</div>
				<div className={'right'}>
					{`${AccountCurrencySymbol} ${rateValue}`}
				</div>
			</div>
		</div>
	);
};

export default ItemTemplate;


