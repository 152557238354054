import React from 'react';
import TextBox from 'devextreme-react/text-box';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';

const {PAYMENT: {PAYMENT_TYPE_INCOME}} = vars;

const FieldTemplate = ({data}) => {
	const {t} = useTranslation();

	if (!data) {
		return <TextBox disabled defaultValue={t('COMMON.SELECT_BOX')}/>
	}

	const {
		CurrencyCode = null,
		CurrencyName = null,
		ImageUrl = null,
		AccountCurrencySymbol = null,
		DisplayCurrencyRate = null,
		DisplayBuyCurrencyRate = null,
		PaymentTypeID = null,
	} = data;

	const rateValue = PaymentTypeID === PAYMENT_TYPE_INCOME ? DisplayCurrencyRate : DisplayBuyCurrencyRate;

	return (
		<div className={'crypto-item-field'}>
			<div className={'image-wrapper'}>
				{ImageUrl && (
					<img src={ImageUrl} alt={CurrencyName}/>
				)}
			</div>
			<div className={'field-wrapper'}>
				<div className={'left'}>
					<span className={'left-bold'}>{CurrencyCode}</span>
					<span>{CurrencyName}</span>
				</div>
				<div className={'right'}>{`${AccountCurrencySymbol} ${rateValue}`}</div>
			</div>
			<TextBox
				readOnly
				visible={false}
				className={'crypto-display-text'}
				defaultValue={CurrencyCode}
			/>
		</div>
	);
};

export default FieldTemplate;


